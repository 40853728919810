<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold title">
          Alunos <span class="tags_count">{{ count }}</span>
        </h2>
      </b-col>

      <b-col class="text-right">
        <b-button style="margin-left: 25px" variant="outline" @click="openModal">Liberar para
          turma
        </b-button>
        <template v-if="!isLoadingMassReport">
          <b-button class="btn blue" @click="generateMassReport">
            Relatório Geral
          </b-button>
        </template>
        <template v-else>
          <b-spinner style="margin-right: 25%;" variant="success" label="Carregando..."></b-spinner>
        </template>
      </b-col>
    </b-row>

    <b-row class="mt-50">
      <b-col>
        <SelectCycles v-model="cycle" :callback="callbackCycles"></SelectCycles>
        <span class="clear-field" v-if="this.cycle != null" @click="clearCycles">Limpar filtro</span>
      </b-col>
      <b-col>
        <SelectClassrooms v-model="classroom" :callback="callbackClassrooms" :cycle="cycle_select"></SelectClassrooms>
        <span class="clear-field" v-if="this.classroom != null" @click="clearClassromms">Limpar filtro</span>
      </b-col>

      <b-col>
        <b-form-group label="Buscar aluno">
          <b-form-input type="search" size="sm" placeholder="Digite aqui" v-model="search"
            @change="searchStudent"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-container class="list-top" fluid>
        <b-row class="header-list-teacher" v-if="wasRequestMade && students.length">
          <b-col :md="8">
            <p class="bold">Nome</p>
          </b-col>
          <b-col>
            <p class="bold">Ciclo</p>
          </b-col>
          <b-col>
            <p class="bold" style="text-align: center">Informe Periódico</p>
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid>
        <b-row align-v="center" class="list-teacher" v-for="student in students" :key="student.id">
          <b-col :md="8" class="name">
            <p class>{{ student.User.name }}</p>
          </b-col>

          <b-col class="cycles">
            <p class="tags">{{ student.Cycle.name }}</p>
          </b-col>

          <b-col class="center-icons">
            <router-link :to="{
              name: 'informe-periodico-detalhes',
              params: { id: student.id },
            }">
              <ChartIcon />
            </router-link>
          </b-col>
        </b-row>

        <b-row align-v="center" class="list-teacher" v-if="wasRequestMade && !students.length">
          <b-col>Nenhum resultado encontrado</b-col>
        </b-row>
        <b-row align-v="center" class="list-teacher" v-if="!wasRequestMade">
          <b-col>Carregando</b-col>
        </b-row>
        <b-row class="align-center" v-if="!search && wasRequestMade">
          <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="count" aria-controls="teachersList"
            last-text first-text>
            <template #prev-text>
              <LeftArrowIcon class="left" />
            </template>
            <template #next-text>
              <LeftArrowIcon />
            </template>
          </b-pagination>
        </b-row>

        <StudentEditData :student="studentToEdit" />

        <StudentEditPassword :student="studentToPassword" />
      </b-container>
    </b-row>
    <b-modal id="modal-att-class" title="Liberar relatórios para turma" size="xl" hide-footer>
      <b-row style="justify-content: space-between;">
        <b-col md="3">
          <b-form-group label="Turmas">
            <multiselect v-model="classroomModal" :options="classrooms" :multiple="false" placeholder="Selecione"
              label="designation" track-by="id" selectLabel="" @select="callbackClassroomsModal"
              @remove="callbackRemoveClassroomsModal">
              <span slot="noResult">Nada encontrado</span>
            </multiselect>
          </b-form-group>
          <span class="clear-field" @click="clearFieldsModal">Limpar filtros</span>
        </b-col>
        <b-col md="3">
          <b-form-group class="label-add" :label="'&nbsp;'">
            <b-button style="width: 100%;" :disabled="selectedClassroomsModal.length <= 0 ||
              studentsModal.length <= 0" @click="submitForModal" class="blue">Liberar relatórios</b-button>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col style="margin-top: 15px" md="12">
          <h2>Alunos</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-container fluid>
            <b-row class="header-list-teacher align-items-center" v-if="studentsModal.length > 0">
              <b-col md="3">
                <p class="bold mb-0">Matrícula</p>
              </b-col>
              <b-col md="7">
                <p class="bold mb-0">Nome</p>
              </b-col>
              <b-col md="2">
                <p class="bold mb-0"></p>
              </b-col>
            </b-row>
          </b-container>
          <b-container fluid>
            <b-row align-v="center" class="list-teacher" v-for="(student, index) in studentsModal" v-bind:key="index">
              <b-col md="3">
                <p>{{ student.id }}</p>
              </b-col>
              <b-col md="7">
                <p>
                  {{ student ? student.name : "Nome não cadastrado" }}
                </p>
              </b-col>
              <b-col md="2">
                <a style="display: flex; justify-content: center" v-on:click.prevent="studentsModal.splice(index, 1)">
                  <DeleteIcon />
                </a>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row v-if="!studentsModal.length > 0">
        <b-col md="12">
          <p v-if="studentsModal.length <= 0">Nenhum resultado encontrado</p>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChartIcon from "@/components/svg/ChartIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import SelectClassrooms from "@/components/inputs/SelectClassrooms.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import StudentEditData from "@/components/modals/StudentEditData.vue";
import StudentEditPassword from "@/components/modals/StudentEditPassword.vue";
import httpRda from "../../../http-rda";
import moment from "moment-timezone";
import DataServiceClassrooms from "@/services/classrooms.service";
export default {
  name: "informe-periodico",
  components: {
    ChartIcon,
    DeleteIcon,
    LeftArrowIcon,
    SelectClassrooms,
    SelectCycles,
    StudentEditData,
    StudentEditPassword,
  },

  data() {
    return {
      nameCol: "Tutoria",
      descOrder: false,
      wasRequestMade: false,
      isLoadingMassReport: false,
      studentToEdit: {},
      studentToPassword: {},
      search: "",
      filter: null,
      cycle_select: null,
      cycle: null,
      classroom: null,
      classrooms: [],
      is_active: true,
      searchTerm: "",
      students: null,
      currentPage: 1,
      count: 0,
      perPage: 10,
      selectedClassroomsModal: [],
      studentsModal: [],
      classroomModal: [],
      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Informe Periódico",
          href: "/informe-periodico",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getAuthData",
    }),

    canEdit() {
      if (!this.user.role) return false;
      return !["teacher", "student", "parent"].includes(this.user.role[0]);
    },
  },

  methods: {
    ...mapActions({
      getStudent: "community/getStudent",
      deleteStudent: "community/deleteStudent",
      updateStatusStudents: "community/updateStatusStudents",
    }),

    async downloadStudentReport(studentId = null) {
      let url;

      if (studentId) {
        url = `/students/data_report?id=${studentId}`;
      } else {
        url = `/students/data_report`;
      }

      try {
        const response = await httpRda.get(url, {
          params: this.filter,
          responseType: "blob",
        });

        const date = moment().format("DD-MM-YYYY-HH:mm:ss").toString();

        const newBlob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        let data = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = data;
        link.download = `Relatorio_de_alunos_${date}`;
        link.click();

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 100);

        const Toast = this.$swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: `Relatório exportado com sucesso!`,
        });

        return response;
      } catch (error) {
        this.loadText = "Exportar";
        this.subjectsSelected = [];

        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel exportar o relatório!",
          footer: "Por favor, tente novamente mais tarde.",
        });

        return error;
      }
    },

    orderbyName() {
      const data = this.students.filter((d) => d.User !== null);
      return data.sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];
        if (a.User.name < b.User.name) return order[0];
        else if (a.User.name > b.User.name) return order[1];

        return 0;
      });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.cycle_select = id;
      if (this.cycle_select == 8 || this.cycle_select == 6) {
        this.nameCol = "Ed. Infantil";
      } else {
        this.nameCol = "Tutoria";
      }
      this.currentPage = 1;
      this.clearClassromms();
      this.listStudents();
    },

    callbackClassrooms({ id }) {
      this.classroom = id !== -1 ? id : null;
      this.currentPage = 1;
      this.listStudents();
    },

    clearFieldsModal() {
      this.classroomModal = [];
      this.studentsModal = [];
    },

    async listStudents(page = 1) {
      const params = {
        limit: this.perPage,
        page,
        search: this.search,
        cycle: this.cycle,
        is_active: this.is_active,
        classroom: this.classroom,
      };

      if (this.search) {
        delete params.limit;
      }

      this.filter = { ...params };
      delete this.filter.limit;
      delete this.filter.page;
      Object.keys(this.filter).forEach(
        (key) =>
          (this.filter[key] == null || this.filter[key] == "") &&
          delete this.filter[key]
      );

      await httpRda.get("/students?include=cycle", { params })
        .then((res) => {
          this.students = res.data.students;
          this.count = res.data.count;
          this.wasRequestMade = true;
        })
        .catch(() => {
        });
    },

    async searchStudent() {
      this.nameCol = "Tutoria";
      this.currentPage = 1;
      this.listStudents();
    },

    clearFields() {
      this.nameCol = "Tutoria";
      this.search = "";
      this.currentPage = 1;
      this.listStudents();
    },

    clearCycles() {
      this.nameCol = "Tutoria";
      this.cycle = null;
      this.currentPage = 1;
      this.listStudents();
    },

    clearClassromms() {
      this.classroom = null;
      this.currentPage = 1;
      this.listStudents();
    },

    setStudent(student) {
      this.studentToEdit = student;
    },

    setStudentPassword(student) {
      this.studentToPassword = student;
    },

    confirmStudentDeletion(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.deleteStudent(id);
              this.$swal
                .fire("Excluído!", "Aluno foi excluído.", "success")
                .then((response) => {
                  this.listStudents();
                  return response.data;
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },

    async generateMassReport() {
      if (!this.cycle) {
        this.$swal.fire({
          icon: "error",
          title: "Atenção!",
          text: "Selecione um ciclo para gerar o relatório geral!",
        });
        return;
      }

      try {
        this.isLoadingMassReport = true;

        this.$swal.fire({
          title: 'Gerando relatórios...',
          text: 'Isso pode levar alguns minutos',
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          }
        });

        const response = await httpRda.get(
          `/bimontly-report/report?cycle_id=${this.cycle}`
        );

        const link = document.createElement('a');
        link.href = response.data.downloadUrl;
        link.download = `relatorios_ciclo_${this.cycle}_${new Date().getTime()}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.isLoadingMassReport = false;

        this.$swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Relatórios gerados com sucesso! O download começará automaticamente.",
        });

      } catch (err) {
        this.isLoadingMassReport = false;

        this.$swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Ocorreu um erro ao gerar os relatórios. Por favor, tente novamente.",
        });
      }
    },

    openModal() {
      this.$bvModal.show("modal-att-class");
      this.getClassrooms();
    },

    callbackRemoveClassroomsModal({ id }) {
      this.selectedClassroomsModal = this.selectedClassroomsModal.filter(
        (classroom) => classroom !== id
      );
      this.studentsModal = [];
      this.getModalData();
    },

    callbackClassroomsModal(element) {
      this.selectedClassroomsModal = [element.id];
      this.getModalData();
    },


    getModalData() {
      httpRda
        .get(`/students-for-period-report?classrooms=${this.selectedClassroomsModal.join()}`)
        .then((response) => {
          this.studentsModal = response.data.students;
        });
    },

    getClassrooms() {
      if (this.classrooms.length > 0) {
        return;
      }

      DataServiceClassrooms.getAll()
        .then((response) => {
          this.classrooms = response.data.classrooms;
          if (this.classrooms.length) {
            this.classrooms = this.classrooms.map((classroom) => {
              return {
                id: classroom.id,
                designation: classroom.designation,
                calendar_id: classroom.calendar_id,
              };
            });
          }
        })
        .catch((error) => {
          return error.data;
        });
    },

    async submitForModal() {
      this.$swal
        .fire({
          title: "Atenção!",
          text: "Deseja realmente liberar os relatórios para os alunos selecionados?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Liberar",
          cancelButtonText: "Cancelar",
        })
        .then(async () => {
          const payload = this.studentsModal.map(student => ({
            student_id: student.id,
            description: "",
            teacher_id: null,
            status: "approved",
          }));

          await httpRda
            .post("/period_reports", payload)
            .then(async () => {
              await this.getModalData();
              this.$swal.fire({
                icon: "success",
                title: "Sucesso!",
                text: "",
              });
            });
        }).catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel aprovar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },
  },

  watch: {
    currentPage() {
      this.listStudents(this.currentPage);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },

  async created() {
    await this.listStudents();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.center-icons {
  display: flex;
  justify-content: space-evenly;
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tags_count {
    background-color: #68c5b7;
    font-size: 18px;
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    padding: 3px 10px;
    height: max-content;
  }
}

.list-top {
  margin-top: 30px;
  margin-bottom: 30px;
}

.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;

  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  p {
    margin: 0;
  }

  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;

      .comma {
        margin-right: 4px;
      }

      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }

  .cycles {
    display: flex;
    flex-wrap: wrap;

    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.outline {
  color: #fff !important;
  background: transparent;
  max-width: 220px !important;
  margin-right: 20px;

  &:hover {
    color: #68c5b7 !important;
  }
}

.text-right {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
